define("cb-ui/components/app-top-navbar", ["exports", "cb-ui/config/environment", "cb-ui/utils/session-manager", "cb-ui/utils/append-access-token", "cb-ui/utils/get-target-domain"], function (_exports, _environment, _sessionManager, _appendAccessToken, _getTargetDomain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tenantsEndpoint: _environment.default['tenants'].tenantsEndpoint,
    classNames: ['border-bottom', 'white-bg navbar-top-container'],
    notifications: Ember.inject.service(),
    ieDetection: Ember.inject.service(),
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    profile: Ember.inject.service('profile-validation'),
    showInfo: false,
    quote: null,
    tenantName: null,
    tenantLogo: null,
    tenantLogoError: false,
    userFullName: Ember.computed('sessionContext.currentUser.firstName', 'sessionContext.currentUser.lastName', function () {
      var user = this.get('sessionContext.currentUser');

      if (user) {
        return (user.get('firstName') + ' ' + user.get('lastName')).trim();
      }
    }),
    userIsAgent: Ember.computed('sessionContext.currentUser.firstName', 'sessionContext.currentUser.lastName', function () {
      var user = this.get('sessionContext.currentUser');
      return user.get('isStaff');
    }),
    nextUrl: Ember.computed('session.data.authenticated.token', function () {
      return (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/organization/users"), this.get('session.data.authenticated.token'));
    }),
    nextDashboardUrl: Ember.computed('session.data.authenticated.token', function () {
      return (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/rfp"), this.get('session.data.authenticated.token'));
    }),
    notificationsUrl: Ember.computed('session.data.authenticated.token', function () {
      return (0, _appendAccessToken.default)("".concat((0, _getTargetDomain.default)(), "/dashboard/notifications/all"), this.get('session.data.authenticated.token'));
    }),
    tenantNameObserver: Ember.on('init', Ember.observer('sessionContext.currentUser', function () {
      var _this = this;

      Ember.$.ajax({
        url: this.tenantsEndpoint,
        type: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: "Bearer ".concat(_sessionManager.default.getAccessToken())
        }
      }).then(function (data, statusText, response) {
        var currentTenantId = _sessionManager.default.getCurrentTenantId();

        if (currentTenantId) {
          var tenant = data.find(function (tenant) {
            return tenant.id === currentTenantId;
          });

          if (tenant) {
            _this.set('tenantName', tenant.name);

            _this.set('tenantLogo', tenant.logo);
          }
        }
      }, function () {});
    })),
    removeProgressBar: function () {
      Ember.$("#progressBar").remove();
    }.on('didInsertElement'),
    detectBrowser: function () {
      if (this.get('ieDetection').usesIe()) {
        this.get('router').transitionTo('ie-detected');
      }
    }.on('didInsertElement'),
    profileValidation: function () {
      var _this2 = this;

      Ember.run.later(function () {
        var profileAlert = _this2.get('profile');

        if (profileAlert.showAlert()) {
          if (!_this2.get('sessionContext.currentUser.emailValidated')) {
            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.emailNotConfirmed').toString());
          }

          if (!_this2.get('sessionContext.currentUser.tcbValidated')) {
            _this2.get('notify').error(_this2.get('intl').t('notifyMessages.accountNotValidated').toString());
          }

          profileAlert.alertShown();
        }
      }, '2000');
    }.on('didInsertElement'),
    actions: {
      handleTenantLogoLoadFail: function handleTenantLogoLoadFail() {
        this.set('tenantLogoError', true);
      },
      logout: function logout() {
        this.sendAction('logout');
      },
      markAllAsRead: function markAllAsRead() {
        this.get('notifications').markAllAsRead();
      },
      changeUserLanguage: function changeUserLanguage(language) {
        this.sendAction('changeUserLanguage', language);
      },
      contactTCB: function contactTCB() {
        this.sendAction('contactTCB');
      }
    }
  });

  _exports.default = _default;
});